import React, { FC, memo } from 'react'
import { EBannerTemplate } from '@ucheba/store/adv/types'
import { IBannerProps } from '../types'
import { useBannerCore } from '../bll'
import { BannerHorizontal } from './BannerHorizontal'
import { BannerVertical } from './BannerVertical'
import BannerNotice from './BannerNotice'
import { BannerContentHorizontal } from './BannerContentHorizontal'
import { BannerBottom } from '../touch/BannerBottom'
import { BannerLidFormHorizontal } from './BannerLidFormHorizontal'
import { BannerLidFormNotice } from './BannerLidFormNotice'
import { BannerLidFormVertical } from '../touch/BannerLidFormVertical'

/** Компонент рекламный баннерр */
const Banner: FC<IBannerProps> = (props) => {
  const { spacing = null, id, onDataExist, extraParams, onClick } = props

  const { content, pixelImg, advBannerId } = useBannerCore({
    id,
    onDataExist,
    extraParams,
  })

  if (!content) return null

  return (
    <>
      {content?.template === EBannerTemplate.horizontal && content && (
        <BannerHorizontal spacing={spacing} content={content} pixelImg={pixelImg} />
      )}

      {content?.template === EBannerTemplate.contentHorizontal && content && (
        <BannerContentHorizontal
          spacing={spacing}
          content={content}
          pixelImg={pixelImg}
        />
      )}

      {content?.template === EBannerTemplate.vertical && content && (
        <BannerVertical content={content} pixelImg={pixelImg} />
      )}

      {content?.template === EBannerTemplate.notice && content && (
        <BannerNotice content={content} pixelImg={pixelImg} />
      )}

      {content?.template === EBannerTemplate.bottom && content && (
        <BannerBottom content={content} onClick={onClick} />
      )}

      {content?.template === EBannerTemplate.horizontalForm && content && (
        <BannerLidFormHorizontal
          spacing={spacing}
          content={content}
          pixelImg={pixelImg}
          id={advBannerId}
          bannerId={id}
        />
      )}

      {content?.template === EBannerTemplate.contentHorizontalForm && content && (
        <BannerLidFormHorizontal
          spacing={spacing}
          content={content}
          pixelImg={pixelImg}
          id={advBannerId}
          bannerId={id}
        />
      )}

      {content?.template === EBannerTemplate.noticeForm && content && (
        <BannerLidFormNotice
          content={content}
          pixelImg={pixelImg}
          id={advBannerId}
          bannerId={id}
        />
      )}

      {content?.template === EBannerTemplate.verticalForm && content && (
        <BannerLidFormVertical
          content={content}
          pixelImg={pixelImg}
          id={advBannerId}
          bannerId={id}
        />
      )}
    </>
  )
}

export { Banner }
export default memo(Banner)
