import React, { FC, memo, useRef } from 'react'
import styled from 'styled-components'
import { Box } from '@ucheba/ui/components/Box/desktop'
import { Text } from '@ucheba/ui/components/Text/desktop'
import { Tooltip } from '@ucheba/ui/components/Tooltip/desktop'
import { Notice } from '@ucheba/ui/components/Notice/desktop'
import { Heading } from '@ucheba/ui/components/Heading/desktop'
import { Spacing } from '@ucheba/ui/components/Spacing/desktop'
import { EActivityInfoPart, EActivityTypes } from '@ucheba/utils/types/userActivityTypes'
import { useNotice } from '@ucheba/ui/components/Notice/bll'
import { EFunnels } from '@ucheba/utils/types/funnels'
import { ETypeRequest } from '@ucheba/utils/types/typeRequest'
import { EProductTypes } from '@ucheba/utils/types/productTypes'
import { useSelector } from 'react-redux'
import { authSelectors } from '@ucheba/store/auth'
import { IBannerLidForm } from '../types'
import LeadForm from '../../LeadForm/desktop'
import LidMagnetForm from '../../LidMagnetForm/desktop'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
`

const AdvText = styled.div`
  position: absolute;
  left: -20px;
  top: 0;
  z-index: 999;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: default;
  width: 20px;
`

const TextAdv = styled(Text)`
  writing-mode: vertical-rl;
  transform-origin: center;
  transform: rotate(-180deg);
  letter-spacing: 1px;
`

/** Компонент рекламный баннерр c лид формой */
const BannerLidFormHorizontal: FC<IBannerLidForm> = (props) => {
  const { content, pixelImg, id, spacing, bannerId } = props
  const refDate = useRef<number>(Date.now())
  const {
    text,
    heading,
    color_theme,
    clientName,
    overTitle,
    url_text,
    image_url,
    lead_note,
    lead_institution_id,
  } = content

  const isAuth = useSelector(authSelectors.isAuth)
  const notices = useNotice(
    `leadFormBannerId${lead_institution_id}${id}${bannerId}${refDate.current}`
  )

  return (
    <>
      {!!spacing?.top && <Spacing spacing={spacing.top} />}

      <Wrapper as={Box} color={color_theme as any} spacing='null'>
        <div
          style={{
            width: '100%',
          }}
        >
          {lead_institution_id && (
            <LeadForm
              institutionId={Number(lead_institution_id)}
              activityType={EActivityTypes.lead_form_request}
              leadNote={lead_note}
              infoPart={EActivityInfoPart.lead_form}
              size='small'
              title={heading}
              text={text}
              onSubmitForm={() => {
                notices.addNotice()
              }}
              withMessengers={false}
              overTitle={overTitle}
              buttonText={url_text}
              imgUrl={image_url}
              color={color_theme as any}
            />
          )}

          {!lead_institution_id && (
            <LidMagnetForm
              size='small'
              title={heading}
              text={text}
              onSubmitForm={() => {
                if (isAuth) {
                  notices.addNotice()
                }
              }}
              onCheckedCode={() => {
                notices.addNotice()
              }}
              withMessengers={false}
              overTitle={overTitle}
              buttonText={url_text}
              imgUrl={image_url}
              color={color_theme as any}
              funnel={EFunnels.ucheba_search_lead_form}
              requestTarget={ETypeRequest.ege_lead_request}
              productType={EProductTypes.ege}
              showInputs={!isAuth}
            />
          )}

          {pixelImg && (
            <img src={pixelImg} style={{ width: '1px', height: '1px' }} alt='' />
          )}
        </div>

        {clientName && (
          <AdvText>
            <Tooltip
              content={<Text color='black'>{clientName}</Text>}
              position='right-center'
              width='504px'
            >
              <TextAdv fontSize='xsmall1' lineHeight='xsmall1' color='gray25'>
                РЕКЛАМА
              </TextAdv>
            </Tooltip>
          </AdvText>
        )}

        <Notice
          width='518px'
          color='white'
          showClose={false}
          onClose={notices.removeNotice}
          open={notices.open}
          preventAutoClose={false}
        >
          <Heading h3='Заявка успешно отправлена' spacing='null' />
        </Notice>
      </Wrapper>

      {!!spacing?.top && <Spacing spacing={spacing.top} />}
    </>
  )
}

export { BannerLidFormHorizontal }
export default memo(BannerLidFormHorizontal)
