import React, { FC, memo, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useNotice } from '@ucheba/ui/components/Notice/bll'
import { Notice } from '@ucheba/ui/components/Notice/desktop'
import { Text } from '@ucheba/ui/components/Text/desktop'
import { Tooltip } from '@ucheba/ui/components/Tooltip/desktop'
import { Heading } from '@ucheba/ui/components/Heading/desktop'
import { EActivityInfoPart, EActivityTypes } from '@ucheba/utils/types/userActivityTypes'
import { EFunnels } from '@ucheba/utils/types/funnels'
import { ETypeRequest } from '@ucheba/utils/types/typeRequest'
import { EProductTypes } from '@ucheba/utils/types/productTypes'
import { useSelector } from 'react-redux'
import { authSelectors } from '@ucheba/store/auth'
import { bannerNoticeId } from '../constants'
import { IBannerLidForm } from '../types'
import LeadForm from '../../LeadForm/desktop'
import LidMagnetForm from '../../LidMagnetForm/desktop'

const NoticeSC = styled(Notice)`
  position: relative;
  overflow: auto;
`

const AdvText = styled.div`
  position: absolute;
  right: -40px;
  top: 0;
  z-index: 999;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: default;
  width: 40px;
`

const TextAdv = styled(Text)`
  writing-mode: vertical-rl;
  letter-spacing: 1px;
`

/** Компонент рекламный баннер */
const BannerLidFormNotice: FC<IBannerLidForm> = (props) => {
  const { content, pixelImg, id, bannerId } = props
  const isAuth = useSelector(authSelectors.isAuth)

  const {
    text,
    url_text,
    image_url,
    heading,
    color_theme,
    overTitle,
    clientName,
    lead_note,
    lead_institution_id,
  } = content

  const bannerNotice = useNotice(bannerNoticeId)

  useEffect(() => {
    if (!bannerNotice.open && content) {
      setTimeout(() => {
        bannerNotice.addNotice()
      }, 500)
    }
  }, [])

  const refDate = useRef<number>(Date.now())
  const notices = useNotice(
    `leadFormBannerId${lead_institution_id}${id}${bannerId}${refDate.current}`
  )

  return (
    <>
      <NoticeSC
        spacing='null'
        color={color_theme}
        hideProgress
        open={bannerNotice.open}
        overflow='visible'
        width='518px'
      >
        {lead_institution_id && (
          <LeadForm
            institutionId={Number(lead_institution_id)}
            activityType={EActivityTypes.lead_form_request}
            leadNote={lead_note}
            infoPart={EActivityInfoPart.lead_form}
            size='small'
            title={heading}
            text={text}
            onSubmitForm={() => {
              notices.addNotice()

              bannerNotice.removeNotice()
            }}
            withMessengers={false}
            overTitle={overTitle}
            buttonText={url_text}
            imgUrl={image_url}
            color={color_theme as any}
            titleSize='h3'
          />
        )}

        {!lead_institution_id && (
          <LidMagnetForm
            funnel={EFunnels.ucheba_search_lead_form}
            requestTarget={ETypeRequest.ege_lead_request}
            productType={EProductTypes.ege}
            showInputs={!isAuth}
            size='small'
            title={heading}
            text={text}
            onSubmitForm={() => {
              if (isAuth) {
                notices.addNotice()

                bannerNotice.removeNotice()
              }
            }}
            onCheckedCode={() => {
              notices.addNotice()

              bannerNotice.removeNotice()
            }}
            withMessengers={false}
            overTitle={overTitle}
            buttonText={url_text}
            imgUrl={image_url}
            color={color_theme as any}
            titleSize='h3'
          />
        )}

        {pixelImg && (
          <img src={pixelImg} style={{ width: '1px', height: '1px' }} alt='' />
        )}

        {clientName && (
          <AdvText>
            <Tooltip
              content={<Text color='black'>{clientName}</Text>}
              position='left-center'
              width='500px'
            >
              <TextAdv fontSize='xsmall1' lineHeight='xsmall1' color='gray25'>
                РЕКЛАМА
              </TextAdv>
            </Tooltip>
          </AdvText>
        )}
      </NoticeSC>

      <Notice
        width='518px'
        color='white'
        showClose={false}
        onClose={notices.removeNotice}
        open={notices.open}
        preventAutoClose={false}
      >
        <Heading h3='Заявка успешно отправлена' spacing='null' />
      </Notice>
    </>
  )
}

export { BannerLidFormNotice }
export default memo(BannerLidFormNotice)
